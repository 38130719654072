  import * as firebase from "firebase/app";
import { history } from "../../../history";
import "firebase/auth";
import { SET_SETTING, SET_PUBLIC_KEY } from "../types";
import { api } from "../../../api/api";
import toast from 'react-hot-toast';
import { GlobalVariable } from "../../../util/index";
import {
  setActiveUSerAvailble,
  setAuthTokenAction,
  setIsLoginAction,
  setAuthRefreshTokenAction,
  setLoginUserAction,
  setCurrentUser,
  setForgotPasswordUserAction,
  addUserAvailableAction,
  setAvailableAddUserCountAction,
} from "./index";
import { getTranslation } from "../../../i18n/i18n";
import { setLoaderAction } from "../loader/index";
import { getSetting } from "../setting/index";
import { store } from "../../../redux/storeConfig/store";
import { createDiffieHellman } from "crypto-browserify";
import { AES } from "crypto-js";
import { permission, falsePermission } from "./permissionDefaultData";
import { encryptStorage } from "../../../util/encryptStorage";
import * as constantData from "../../../constants";

const api_status = GlobalVariable.apiResponseStatus;

/**
 @name  resendOtp
 @file loginactions.js
 @description This method is used for login user 
 */
export const submitLoginWithFireBase = (user) => {
  let { email, password } = user;
  return async (dispatch, store) => {
    await dispatch(genaretPublicKey());
    if (!email) {
      toast.error(getTranslation("GLOBLE_MESSAGE.emailRequired"));
      return true;
    }
    if (!email.match(constantData.EMAIL_VALIDATION)) {
      toast.error(getTranslation("GLOBLE_MESSAGE.invalidEmail"));
      return true;
    }
    if (!password) {
      toast.error(getTranslation("GLOBLE_MESSAGE.passwordRequired"));
      return true;
    }
    let encrytPassword = await createEncryptString(password);
    dispatch(setLoaderAction(true));
    let userData = {
      email: email,
      password: encrytPassword.encryt,
      keyId: store()?.auth?.login?.publickKey?.id,
      key: encrytPassword.frontEndKey,
      isOtp: true,
      panel: "merchant",
    };
    let data = await api("admin/login-email", userData, "post");
    if (data.status === api_status.success) {
      if (
        data.data.data.token &&
        data?.data &&
        data?.data?.data?.merchant?.twoFA === false
      ) {
        dispatch(withoutOTPLogin(data));
      } else {
        let localData = {
          token: data.data.data.token,
          permission: falsePermission,
        };

        dispatch(setAuthTokenAction(data.data.data.token));
        encryptStorage.setItem(
          GlobalVariable.localStoreName,
          JSON.stringify(localData)
        );
        dispatch(setLoginUserAction(localData));
        dispatch(setLoaderAction(false));
        toast.success(getTranslation("GLOBLE_MESSAGE.codeSendMsg"));
        history.push("/verify-otp");
      }
    } else if (
      data.status === api_status.error &&
      data.message.data.isPasswordChanged === true
    ) {
      encryptStorage.setItem(
        GlobalVariable.isPChanged,
        data.message.data.isPasswordChanged
      );
      encryptStorage.setItem(
        GlobalVariable.merchantToken,
        data.message.data.token.token
      );
      dispatch(setAuthTokenAction(data.message.data.token.token));
      await dispatch(setLoaderAction(false));
      history.push("/change-password");
      toast.error(data.message.message);
    } else {
      toast.error(data.message.message);
      dispatch(setLoaderAction(false));
    }
  };
};

export const logoutWithJWT = () => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} });
    history.push("/pages/login");
  };
};

export const logoutWithFirebase = (user) => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} });
    history.push("/pages/login");
  };
};

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: "CHANGE_ROLE", userRole: role });
};

/**
 @name  resegetProfilendOtp
 @file loginactions.js
 @description This method is used for get login login user profile
 */
export const getProfile = (name) => {
  return async (dispatch, store) => {
    if (store().auth.login.user && store().auth.login.user.merchantID) {
      let data = await api(
        `merchant/${store().auth.login.user.merchantID}`,
        {},
        "get"
      );
      if (data.status === api_status.success) {
        let storeData = {
          name: "",
          arName: "",
          logoUrl: data.data.data.logoURL,
          displayUrl: data.data.data.displayImage,
          mobileLogoURL: data.data.data.mobileLogoURL,
          printLogo: data.data.data.printLogo,
          licenseCount: data.data.data.licenseCount,
          agentPerformance: data.data.data.agentPerformance,
          servicePerformance: data.data.data.servicePerformance,
          isEvent:data.data.data.isEvent,
          isDonate:data.data.data.categoryID.isDonate,
          otherEmail:data.data.data.otherEmail,
          isAppointment:data.data.data.isAppointment,
          isEservice:data.data.data.isEservice,
          isWhatsappNotify:data.data.data.isWhatsappNotify,
          isLiveFeed:data.data.data.isLivefeed,
          isGovernment:data.data.data.isGovernment,
          isConciergeText:data?.data?.data?.isConciergeText,
          isSDK:data?.data?.data?.isSDK,
          isPhoneEncryption:data?.data?.data?.isPhoneEncryption,
          merchantAppointmentConfig:data?.data?.data?.merchantAppointmentConfig,
          isServiceSms : data?.data?.data?.isServiceSms
        };
        for (let i = 0; i < data.data.data.merchantName.length; i++) {
          if (data.data.data.merchantName[i].lang === "en") {
            storeData.name = data.data.data.merchantName[i].name;
          }
          if (data.data.data.merchantName[i].lang === "ar") {
            storeData.arName = data.data.data.merchantName[i].name;
          }
        }
        dispatch(setCurrentUser(storeData));
        dispatch(getTotalUserCount());
      }
    }
  };
};

export const forgotPasswordResendTokenAction = (data) => {
  return async (dispatch, store) => {
    if (!data) {
      toast.error(getTranslation("GLOBLE_MESSAGE.emailRequired"));
      return true;
    }
    if (!data.match(constantData.EMAIL_VALIDATION)) {
      toast.error(getTranslation("GLOBLE_MESSAGE.invalidEmail"));
      return true;
    }
    dispatch(setLoaderAction(true));
    let forgotData = await api(
      "user/resendToken",
      { email: data, role: "merchant" },
      "put"
    );
    if (forgotData.status === api_status.success) {
      dispatch(setLoaderAction(false));
    } else {
      toast.error(forgotData.message);
      dispatch(setLoaderAction(false));
    }
  };
};

export const setForgotPasswordAction = (data) => {
  return async (dispatch, store) => {
    await dispatch(genaretPublicKey());
    if (!data.token) {
      toast.error(getTranslation("GLOBLE_MESSAGE.codeRequired"));
      return true;
    }

    if (data.token.length !== 6) {
      toast.error(getTranslation("GLOBLE_MESSAGE.codeNotValid"));
      return true;
    }

    if (!data.password) {
      toast.error(getTranslation("GLOBLE_MESSAGE.passwordRequired"));
      return true;
    }

    if (!data.confirmPassword) {
      toast.error(getTranslation("GLOBLE_MESSAGE.cPasswordRequired"));
      return true;
    }

    if (data.confirmPassword !== data.password) {
      toast.error(getTranslation("GLOBLE_MESSAGE.password&ConfirmNotSame"));
      return true;
    }
    let encrytPassword = await createEncryptString(data.password);
    let finalData = {
      password: encrytPassword.encryt,
      confirmPassword: encrytPassword.encryt,
      keyId: store()?.auth?.login?.publickKey?.id,
      key: encrytPassword.frontEndKey,
      token: data.token,
      id: data.id,
      tokenCode: data.tokenCode,
    };
    dispatch(setLoaderAction(true));
    let setPassword = await api(
      `admin/adminSetForgotPassword`,
      finalData,
      "post"
    );

    if (setPassword.status === api_status.success) {
      dispatch(setForgotPasswordUserAction({}));
      toast.success(getTranslation("GLOBLE_MESSAGE.passwordChange"));
      dispatch(setLoaderAction(false));
      history.push("/login");
    } else {
      toast.error(setPassword.message.message);
      dispatch(setLoaderAction(false));
    }
  };
};

/**
 @name  getTotalUserCount
 @file loginactions.js
 @description get total number of user count
 */
export const getTotalUserCount = () => {
  return async (dispatch, store) => {
    if (store().auth.login.user && store().auth.login.user.merchantID) {
      let data = await api(
        `getUserCountByMerchant?merchantID=${
          store().auth.login.user.merchantID
        }`,
        {},
        "get"
      );
      if (data.status === api_status.success) {
        if (
          data.data.data.count >= store().auth.login.currentUser.licenseCount
        ) {
          dispatch(addUserAvailableAction(false));
        } else {
          dispatch(addUserAvailableAction(true));
        }
        dispatch(setAvailableAddUserCountAction(data.data.data.count));
        dispatch(
          setActiveUSerAvailble(
            parseInt(store().auth.login.currentUser.licenseCount) -
              data.data.data.activeCount
          )
        );
      }
    }
  };
};

/**
 @name  logOutAction
 @file loginactions.js
 @description logout current login user
 */
export const logOutAction = () => {
  return async (dispatch, store) => {
    let logoutData = await api("admin/logout", {}, "post");
    if (logoutData.status === api_status.success) {
      encryptStorage.removeItem(GlobalVariable.localStoreName);
      encryptStorage.removeItem(GlobalVariable.loginId);

      let data = {
        permission: permission,
      };
      await dispatch(setLoginUserAction(data));
      toast.success(logoutData.data.message);
    }
    history.push("/login");
  };
};

/**
 @name  getserviceBranchById
 @file loginactions.js
 @description get service of user
 */
export const getserviceBranchById = async (branchID) => {
  let getService = await api(`getByBranchID?branchID=${branchID}`, {}, "get");
  if (getService.status === api_status.success) {
    return getService.data.data.service;
  }
};
/**
 @name  getAgentsBranchById
 @file loginactions.js
 @description get agents of user
 */
export const getAgentsBranchById = async (branchID, merchantId) => {
  let getAgents = await api(
    `agent/list?role=agent&branchId=${branchID}&merchantId=${merchantId}&isOnline=${false}`,
    {},
    "get"
  );
  if (getAgents.status === api_status.success) {
    return getAgents.data.data.user;
  } else {
    return [];
  }
};
/**
 @name  getMerchantSetting
 @file loginactions.js
 @description get merchant settings
 */
export const getMerchantSetting = async (email) => {
  const data = await api(`merchant/settingUser?email=${email}`, {}, "get");
  if (data.status === api_status.success) {
    store.dispatch({
      type: SET_SETTING,
      payload: data.data.data,
    });
  }
};
/**
 @name  invalidPasswordCheck
 @file loginactions.js
 @description check creds match  
 */
export const invalidPasswordCheck = async (password) => {
  let passwordPolicy = store.getState().auth.login.user.passwordPolicy;
  if (passwordPolicy) {
    let minNumbers = store.getState().setting.setting.setting.minNumbers;
    let minLowerCase = store.getState().setting.setting.setting.minLowerCase;
    let minUpperCase = store.getState().setting.setting.setting.minUpperCase;
    let minSpecialChar =
      store.getState().setting.setting.setting.minSpecialChar;
    let minPasswordLength =
      store.getState().setting.setting.setting.minPasswordLength;
    let maxPasswordLength =
      store.getState().setting.setting.setting.maxPasswordLength;
    let regexString = `^(?=(?:[^a-z]*[a-z]){${minLowerCase},})(?=(?:[^A-Z]*[A-Z]){${minUpperCase},})(?=(?:\\D*\\d){${minNumbers},})(?=(?:[^#$^+=!*()@%&]*[#$^+=!*()@%&]){${minSpecialChar},}).{${minPasswordLength},${maxPasswordLength}}$`;
    if (!password.match(regexString)) {
      toast.error(
        `The length of the password must be between ${minPasswordLength} to ${maxPasswordLength} characters.` +
          `${
            minUpperCase > 0 ||
            minLowerCase > 0 ||
            minNumbers > 0 ||
            minSpecialChar > 0
              ? ` Password must contain at least ${
                  minUpperCase > 0 ? minUpperCase + " uppercase letter , " : ""
                }${
                  minLowerCase > 0 ? minLowerCase + " lowercase letter ," : ""
                }  ${minNumbers > 0 ? minNumbers + " number ," : ""}   ${
                  minSpecialChar > 0
                    ? minSpecialChar + " special character"
                    : ""
                } .`
              : ""
          } `
      );
      return true;
    }
  } else {
    if (!password.match(constantData.PASSWORD_VALIDATION)) {
      toast.error(getTranslation("GLOBLE_MESSAGE.invalidPassword"));
      return true;
    }
  }
};

/**
 @name  genaretPublicKey
 @file loginactions.js
 @description ganerate publick key for creds encryt  
 */
export const genaretPublicKey = () => {
  return async (dispatch, store) => {
    // /generate-public-key
    let publicKeyData = await api("generate-public-key", {}, "get");
    if (publicKeyData.status === api_status.success) {
      dispatch({ type: SET_PUBLIC_KEY, payload: publicKeyData.data.data });
      return publicKeyData.data.data
    }
  };
};

/**
 @name  createEncryptString
 @file loginactions.js
 @description create encryt creds string using genaretPublicKey() function
 */
export const createEncryptString = async (password) => {
  let publickKey = store.getState().auth.login.publickKey;
  let unjp = JSON.parse(publickKey?.prime);
  let alice = createDiffieHellman(new Uint8Array(unjp.data));
  alice.generateKeys();
  let jsonify = publickKey?.key;
  let frontEndKey = JSON.stringify(alice.getPublicKey());
  let unjsonify = JSON.parse(jsonify);
  let aliceSecret = alice.computeSecret(
    new Uint8Array(unjsonify.data),
    null,
    "hex"
  );

  let encryt = AES.encrypt(password, aliceSecret.toString("hex")).toString();
  let data = {
    encryt,
    frontEndKey,
  };
  return data;
};

export const hourValidation = async (hour) => {
  let regexString = `^\\d+(\\.[0|5]{1,2})?$`;
  if (!hour.toString().match(regexString)) {
    toast.error(getTranslation("AUTH.hourValidation"));
    return true;
  }
};

/**
 @name  withoutOTPLogin
 @file loginactions.js
 @description This method is use for login without otp code is use has not unable 2FA in admin panel  
 */
export const withoutOTPLogin = (data) => {
  return async (dispatch, store) => {
    let loggedIn = false;
    if (data.status === api_status.success) {
      if (data.data.data.role === "merchant") {
        encryptStorage.setItem(
          GlobalVariable.loginId,
          data.data.data.merchant.id
        );
        let localData = {
          merchantID: data.data.data.merchant.id,
          name: data.data.data.merchant.name,
          logoURL: data.data.data.merchant.logoURL,
          categoryID: data.data.data.merchant.categoryID,
          passwordPolicy: data.data.data.merchant.passwordPolicy,
          segmentation: data.data.data.merchant.segmentation,
          exposeAPI: data.data.data.merchant.exposeAPI,
          ThirdPartySignageAPI: data.data.data.merchant.ThirdPartySignageAPI,
          email: data.data.data.email,
          createdBy: data.data.data.createdBy,
          isSignedIn: loggedIn,
          loggedInWith: "firebase",
          isDonate: data?.data?.data?.isDonate,
          role: data.data.data.role,
          permission: permission,
          token: data.data.data.token,
          refreshToken: "",
        };

        await dispatch(setAuthTokenAction(data.data.data.token));
        await dispatch(setAuthRefreshTokenAction(""));
        encryptStorage.setItem(
          GlobalVariable.localStoreName,
          JSON.stringify(localData)
        );
        await dispatch(setLoginUserAction(localData));
        await dispatch(getProfile(data.data.data.merchant.name));
        dispatch(getSetting());
        dispatch(setIsLoginAction(true));
        dispatch(setLoaderAction(false));
        if(data?.data?.data?.isDonate){
          history.push("/donationDashboard");
        }else{
          history.push("/");
        }
      } else if (data.data.data.role === GlobalVariable.manager) {
        encryptStorage.setItem(
          GlobalVariable.loginId,
          data.data.data.merchant.id
        );
        let localData = {
          merchantID: data.data.data.merchant.id,
          branchId: data.data.data.branchID,
          serviceId: data.data.data.serviceID,
          queueId: data.data.data.queueId,
          id: data.data.data.id,
          branchName: data.data.data.branch.name,
          name: data.data.data.name,
          role: data.data.data.role,
          logoURL: data.data.data.merchant.logoURL,
          categoryID: data.data.data.merchant.categoryID,
          passwordPolicy: data.data.data.merchant.passwordPolicy,
          exposeAPI: data.data.data.merchant.exposeAPI,
          ThirdPartySignageAPI: data.data.data.merchant.ThirdPartySignageAPI,
          segmentation: data.data.data.merchant.segmentation,
          email: data.data.data.email,
          createdBy: data.data.data.createdBy,
          isSignedIn: loggedIn,
          loggedInWith: "firebase",
          token: data.data.data.token,
          permission: data.data.data.permission,
          refreshToken: "",
        };
        await dispatch(setAuthTokenAction(data.data.data.token));
        await dispatch(setAuthRefreshTokenAction(""));
        encryptStorage.setItem(
          GlobalVariable.localStoreName,
          JSON.stringify(localData)
        );
        await dispatch(setLoginUserAction(localData));
        await dispatch(getProfile(data.data.data.name));
        dispatch(getSetting());
        dispatch(setIsLoginAction(true));
        dispatch(setLoaderAction(false));
        const permission = store().auth.login.user.permission;
        let dashboardTab = permission && permission[0]?.dashboardTab?.read;
        let appointmentsDashboardTab = permission && permission[14]?.appointmentsDashboardTab?.read;
        let eservicesDashboardTab = permission && permission[15]?.eservicesDashboardTab?.read;
        let serviceTab = permission && permission[1]?.serviceTab?.read;
        let queueTab = permission && permission[2]?.queueTab?.read;
        let userTab = permission && permission[3]?.userTab?.read;
        let branchTab = permission && permission[4]?.branchTab?.read;
        let feedbackTab = permission && permission[5]?.feedbackTab?.read;
        let settingTab = permission && permission[6]?.settingTab?.read;
        let logTab = permission && permission[7]?.logTab?.read;
        let instantTicketsLogTab = permission && permission[16]?.instantTicketsLogTab?.read;
        let appointmentsLogTab = permission && permission[17]?.appointmentsLogTab?.read;
        let eservicesLogTab = permission && permission[18]?.eservicesLogTab?.read;
        let reportTab = permission && permission[8]?.reportTab?.read;
        let workProfileTab = permission && permission[9]?.workProfileTab?.read;
        let categoryTab = permission && permission[10]?.categoryTab?.read;
        let formTab = permission && permission[12]?.formTab?.read;
        let livefeedTab = permission && permission[13]?.livefeedTab?.read;
        let donationDashboardTab = permission && permission[19]?.donationDashboardTab?.read;
        let donationLogTab = permission && permission[20]?.donationLogTab?.read;
        if (dashboardTab) {
          history.push("/");
          return true;
        }
        if (appointmentsDashboardTab) {
          history.push("/appointmentDashboard");
          return true;
        }
        if (eservicesDashboardTab) {
          history.push("/eserviceDashboard");
          return true;
        }
        if (donationDashboardTab) {
          history.push("/donationDashboard");
          return true;
        }
        if (serviceTab) {
          history.push("/services");
          return true;
        }
        if (queueTab) {
          history.push("/queues");
          return true;
        }
        if (userTab) {
          history.push("/users");
          return true;
        }
        if (branchTab) {
          history.push("/branches");
          return true;
        }
        if (reportTab) {
          history.push("/reports");
          return true;
        }
        if (feedbackTab) {
          history.push("/feedback");
          return true;
        }
        if (settingTab) {
          history.push("/setting");
          return true;
        }
        if (logTab) {
          history.push("/logs");
          return true;
        }
        if (instantTicketsLogTab) {
          history.push("/ticketLogs");
          return true;
        }
        if (appointmentsLogTab) {
          history.push("/appointmentsLogs");
          return true;
        }
        if (eservicesLogTab) {
          history.push("/eservicesLogs");
          return true;
        }
        if (donationLogTab) {
          history.push("/donationLogs");
          return true;
        }
        if (workProfileTab) {
          history.push("/work-profile");
          return true;
        }
        if (categoryTab) {
          history.push("/category");
          return true;
        }
        if (formTab) {
          history.push("/forms");
          return true;
        }
        if (livefeedTab) {
          history.push("/livefeed");
          return true;
        }
        history.push("/not-access");
      } else if (data.data.data.role === GlobalVariable.merchantUser) {
        encryptStorage.setItem(
          GlobalVariable.loginId,
          data.data.data.merchant.id
        );
        let localData = {
          merchantID: data.data.data.merchant.id,
          name: data.data.data.name,
          id: data.data.data.id,
          logoURL: data.data.data.merchant.logoURL,
          categoryID: data.data.data.merchant.categoryID,
          passwordPolicy: data.data.data.merchant.passwordPolicy,
          segmentation: data.data.data.merchant.segmentation,
          exposeAPI: data.data.data.merchant.exposeAPI,
          ThirdPartySignageAPI: data.data.data.merchant.ThirdPartySignageAPI,
          email: data.data.data.email,
          createdBy: data.data.data.createdBy,
          isSignedIn: loggedIn,
          loggedInWith: "firebase",
          role: data.data.data.role,
          permission: data.data.data.permission,
          token: data.data.data.token,
          refreshToken: "",
        };
        await dispatch(setAuthTokenAction(data.data.data.token));
        await dispatch(setAuthRefreshTokenAction(""));
        encryptStorage.setItem(
          GlobalVariable.localStoreName,
          JSON.stringify(localData)
        );
        await dispatch(setLoginUserAction(localData));
        await dispatch(getProfile(data.data.data.name));
        dispatch(getSetting());
        dispatch(setIsLoginAction(true));
        dispatch(setLoaderAction(false));
        const permission = store().auth.login.user.permission;
        let dashboardTab = permission && permission[0]?.dashboardTab?.read;
        let appointmentsDashboardTab = permission && permission[14]?.appointmentsDashboardTab?.read;
        let eservicesDashboardTab = permission && permission[15]?.eservicesDashboardTab?.read;
        let serviceTab = permission && permission[1]?.serviceTab?.read;
        let queueTab = permission && permission[2]?.queueTab?.read;
        let userTab = permission && permission[3]?.userTab?.read;
        let branchTab = permission && permission[4]?.branchTab?.read;
        let feedbackTab = permission && permission[5]?.feedbackTab?.read;
        let settingTab = permission && permission[6]?.settingTab?.read;
        let logTab = permission && permission[7]?.logTab?.read;
        let instantTicketsLogTab = permission && permission[16]?.instantTicketsLogTab?.read;
        let appointmentsLogTab = permission && permission[17]?.appointmentsLogTab?.read;
        let eservicesLogTab = permission && permission[18]?.eservicesLogTab?.read;
        let reportTab =
          permission && permission[8]?.reportTab?.read
            ? permission[8]?.reportTab?.read
            : false;
        let workProfileTab = permission && permission[9]?.workProfileTab?.read;
        let categoryTab = permission && permission[10]?.categoryTab?.read;
        let formTab = permission && permission[12]?.formTab?.read;
        let livefeedTab = permission && permission[13]?.livefeedTab?.read;
        let donationDashboardTab = permission && permission[19]?.donationDashboardTab?.read;
        let donationLogTab = permission && permission[20]?.donationLogTab?.read;

        if (dashboardTab) {
          history.push("/");
          return true;
        }
        if (appointmentsDashboardTab) {
          history.push("/appointmentDashboard");
          return true;
        }
        if (eservicesDashboardTab) {
          history.push("/eserviceDashboard");
          return true;
        }
        if (donationDashboardTab) {
          history.push("/donationDashboard");
          return true;
        }
        if (serviceTab) {
          history.push("/services");
          return true;
        }
        if (queueTab) {
          history.push("/queues");
          return true;
        }
        if (userTab) {
          history.push("/users");
          return true;
        }
        if (branchTab) {
          history.push("/branches");
          return true;
        }
        if (reportTab) {
          history.push("/reports");
          return true;
        }
        if (feedbackTab) {
          history.push("/feedback");
          return true;
        }
        if (settingTab) {
          history.push("/setting");
          return true;
        }
        if (logTab) {
          history.push("/logs");
          return true;
        }
        if (instantTicketsLogTab) {
          history.push("/ticketLogs");
          return true;
        }
        if (appointmentsLogTab) {
          history.push("/appointmentsLogs");
          return true;
        }
        if (eservicesLogTab) {
          history.push("/eservicesLogs");
          return true;
        }
        if (donationLogTab) {
          history.push("/donationLogs");
          return true;
        }
        if (workProfileTab) {
          history.push("/work-profile");
          return true;
        }
        if (categoryTab) {
          history.push("/category");
          return true;
        }
        if (formTab) {
          history.push("/forms");
          return true;
        }
        if (livefeedTab) {
          history.push("/livefeed");
          return true;
        }
        history.push("/not-access");
      } else {
        toast.error(getTranslation("AUTH.Invalidcredential"));
        dispatch(setLoaderAction(false));
      }
    } else {
      dispatch(setLoaderAction(false));
      if (data.message.message) {
        toast.error(data.message.message);
      } else {
        toast.error(data.message);
      }
    }
  };
};

/**
 @name  resendOtp
 @file loginactions.js
 @description This method is used for re-send otp for login code 
 */
export const resendOtp = () => {
  return async (dispatch, store) => {
    dispatch(setLoaderAction(true));
    let data = await api("resend-otp", {}, "post");
    if (data.status === api_status.success) {
      toast.success(getTranslation("GLOBLE_MESSAGE.codeSendMsg"));
      dispatch(setLoaderAction(false));
    } else {
      toast.error(data.message.message);
      dispatch(setLoaderAction(false));
    }
  };
};

let isRefreshingToken = false;
let tokenRefreshPromise = null;

export const refresTokenIntervalAction = async () => {
  if (!isRefreshingToken) {
    isRefreshingToken = true;
    tokenRefreshPromise = (async () => {
      try {
        let apigetData = await api('merchant/refreshToken', {}, 'get');
        if (apigetData.status === api_status.success) {
          let data = encryptStorage.getItem(GlobalVariable.localStoreName);
          store.dispatch(setAuthTokenAction(apigetData.data.data.token));
          encryptStorage.setItem(GlobalVariable.localStoreName, JSON.stringify({ ...data, token: apigetData.data.data.token }));
        } else {
          let data = encryptStorage.getItem(GlobalVariable.localStoreName);
          store.dispatch(setAuthTokenAction({}));
          encryptStorage.setItem(GlobalVariable.localStoreName, JSON.stringify({ ...data, token: '' }));
        }
      } finally {
        isRefreshingToken = false;
      }
    })();

    await tokenRefreshPromise;
  } else {
    await tokenRefreshPromise;
  }
};

export const checkTokenRefresh = async () => {
  if (isRefreshingToken) {
    await tokenRefreshPromise;
  }
};